import Container from "../../shared/components/Container";

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "../../shared/components/ui/breadcrumb";
import CardObjetoEducacional from "../../shared/components/oe-card";
import { ObjetoEducacional, Root } from "../../models/ObjetoEducacional";
import { fetchApi } from "../../shared/services/rest-dotnet.service";
import { useEffect, useState } from "react";
import Menu from "../../shared/components/menu";
import { useHistory, useLocation } from "react-router";
import { useScrollTop } from "../../shared/hooks/useScrollTop";
import Pagination from "@mui/material/Pagination";
import qs from "qs";
import { ScrollArea } from "../../shared/components/ui/scroll-area";

const key = process.env.REACT_APP_X_TYPESENSE_API_KEY;

// average_duration:=[5,4]
// production_date:=[1608174000,1608174001]
// has_certificate:=true
// keywords:=["Mamografia","Inteligência Artificial - IA"]
// updated_at:=[1727911728,1727911729]
// label_description:=["Reunião Científica","Fast Challenge"]
// target_audience:=["Estudante Medicina","Estudante Enfermagem"]
// authors:=["Marcelo Fiorelli Alexandrino","Eduardo Juan Troster"]
// subfield_of_knowledge:=["Urgência e Emergência - Geral","Endoscopia Ginecológica"]
// field_of_knowledge:=["Radiologia e Diagnóstico por Imagem","Radiologia Multiprofissional"]
// maturity_level:=["Especialista","Novato"]
// object_type:=Vídeo
// is_sponsored:=false

interface SearchProps {
  q?: string;
  a?: string;
  p?: string;
  m?: string;
  n?: string;
  t?: string;
  s?: string;
  c?: string;
  pa?: string[];
  tp?: string[];
  page?: number;
}

interface TypesenseResponse {
  facet_counts: FacetCount[];
  found: number;
  hits: Hit[];
  out_of: number;
  page: number;
  request_params: RequestParams;
  search_cutoff: boolean;
  search_time_ms: number;
}

interface FacetCount {
  counts: Count[];
  field_name: string;
  sampled: boolean;
  stats: {
    total_values: number;
  };
}

interface Count {
  count: number;
  highlighted: string;
  value: string;
}

export interface Hit {
  document: Document;
  highlight: Highlight;
  highlights: HighlightDetail[];
  text_match: number;
  text_match_info: TextMatchInfo;
}

interface Document {
  authors: string[];
  average_duration: string;
  field_of_knowledge: string[];
  has_certificate: boolean;
  id: string;
  id_ob: string;
  is_sponsored: boolean;
  keywords: string[];
  label_description: string[];
  label_id: number[];
  maturity_level: string[];
  media_id: number;
  media_url: string;
  object_type: string;
  object_type_id: number;
  preview_image_description: string;
  preview_image_url: string;
  production_date: number;
  subfield_of_knowledge: string[];
  summary: string;
  target_audience: string[];
  target_audience_id: number[];
  title: string;
  updated_at: number;
}

export interface Highlight {
  keywords?: MatchedSnippet[];
  subfield_of_knowledge?: MatchedSnippet[];
  summary?: MatchedSnippet;
  title?: MatchedSnippet;
}

interface MatchedSnippet {
  matched_tokens: string[];
  snippet: string;
}

interface HighlightDetail {
  field: string;
  matched_tokens: string[];
  snippet: string;
  indices?: number[];
}

interface TextMatchInfo {
  best_field_score: string;
  best_field_weight: number;
  fields_matched: number;
  num_tokens_dropped: number;
  score: string;
  tokens_matched: number;
  typo_prefix_score: number;
}

interface RequestParams {
  collection_name: string;
  first_q: string;
  per_page: number;
  q: string;
}

async function searchObjetosEducacionais({
  q,
  a,
  p,
  m,
  n,
  t,
  s,
  c,
  pa,
  tp,
  page,
}: SearchProps): Promise<Root> {
  var str = `(${Array.isArray(tp) ? tp.map((type) => `object_type:=${type}`).join(" || ") : [tp].map((type) => `object_type:=${type}`).join(" || ")})`;
  var str2 = `(${Array.isArray(pa) ? pa.map((type) => `authors:=${type}`).join(" || ") : [pa].map((type) => `authors:=${type}`).join(" || ")})`;
  const filt = [str, str2];
  const params: any = {
    q: q || "*",
    page: page || 1,
    per_page: 15,
    preset: "ade_adaptive_full_text_search_v1",
    has_certificate: c === "true",
    label_description: Array.isArray(m) ? m : m ? [m] : [],
    target_audience: Array.isArray(p) ? p : p ? [p] : [],
    maturity_level: Array.isArray(n) ? n : n ? [n] : [],
    field_of_knowledge: Array.isArray(a) ? a : a ? [a] : [],
    object_type: Array.isArray(t) ? t : t ? [t] : [],
    filter_by: filt.filter((a) => a !== "()").join(" && "),
  };

  if (s) {
    params.sort_by =
      s === "1"
        ? "production_date:asc"
        : s === "2"
          ? "production_date:desc"
          : s === "3"
            ? "title:asc"
            : s === "4"
              ? "title:desc"
              : "production_date:asc";
  }

  const payloadParams = Object.fromEntries(
    Object.entries(params).filter(([key, value]) => value !== `() && ()`),
  );

  try {
    const response = await fetch(
      `https://0vbgn4uizxqa2td6p-1.a1.typesense.net/collections/ade_sssearch_1/documents/search?${qs.stringify(payloadParams)}`,
      {
        method: "GET",
        headers: {
          "X-TYPESENSE-API-KEY": key || "",
        },
      },
    );

    const data: TypesenseResponse = await response.json();
    // setResults(data);
    return {
      items: data.hits.map((hit) => ({
        titulo: hit.document.title,
        resumo: hit.document.summary,
        tempoMedioDuracao: hit.document.average_duration,
        descricaoRotulo: hit.document.label_description[0],
        tipoOe: hit.document.object_type,
        imagemPreviewUrl: hit.document.preview_image_url,
        dataProducao: formatUnixTimestamp(hit.document.production_date),
        isPatrocinado: hit.document.is_sponsored,
        possuiCertificado: hit.document.has_certificate,
        id: parseInt(hit.document.id),
        idRotulo: hit.document.label_id[0],
        idVideoAudio: hit.document.media_id,
        finalizado: hit.document.updated_at,
        tipoObjetoId: hit.document.object_type_id,
      })),
      totalItems: data.found,
      totalPages: Math.ceil(data.found / 15),
      pageIndex: data.page,
      facet_counts: data.facet_counts,
      pageSize: 15,
    };
  } catch (error) {
    console.error("Error fetching data:", error);
    return {} as Root;
  } finally {
    // setIsLoading(false);
  }
}

function formatUnixTimestamp(unixTimestamp: number): string {
  // Convert Unix timestamp (in seconds) to milliseconds
  const date = new Date(unixTimestamp * 1000);

  // Format the date with uppercase month abbreviation as "dd MMM yyyy"
  const day = date.toLocaleDateString("pt-BR", { day: "2-digit" });
  const month = date.toLocaleDateString("pt-BR", { month: "short" });
  const year = date.toLocaleDateString("pt-BR", { year: "numeric" });

  return `${day} ${month} ${year}`;
}

export default function ObjetoEducacionalSearch() {
  const [objetos, setObjetos] = useState<Root>();
  const [loading, setLoading] = useState<boolean>(false);
  const [facetCounts, setFacetCounts] = useState<FacetCount[]>([]);

  const [searchParams, setSearchParams] = useState<SearchProps>(
    {} as SearchProps,
  );
  const [page, setPage] = useState<number>(1);
  const scrollTop = useScrollTop();

  const location = useLocation();
  const history = useHistory();

  const loadObjetos = async (search: SearchProps) => {
    setLoading(true);
    const response = await searchObjetosEducacionais(search || searchParams);
    setObjetos(response);
    setFacetCounts(response.facet_counts); // Store the facet counts
    setLoading(false);
  };

  useEffect(() => {
    const queryLocation = new URLSearchParams(location.search);
    const search = {
      q: queryLocation.get("q") || undefined,
      a: queryLocation.get("a") || undefined,
      m: queryLocation.get("m") || undefined,
      n: queryLocation.get("n") || undefined,
      p: queryLocation.get("p") || undefined,
      t: queryLocation.get("t") || undefined,
      s: queryLocation.get("s") || undefined,
      c: queryLocation.get("c") || undefined,
      tp: queryLocation.getAll("tp") || undefined,
      pa: queryLocation.getAll("pa") || undefined,
      page: Number(queryLocation.get("page")) || 1,
    };
    setSearchParams(search);
    loadObjetos(search);
    setPage(Number(queryLocation.get("page")) || 1);
  }, [location.search, page]);

  scrollTop();

  return (
    <>
      <Container className="h-full min-h-screen  py-9 pb-9">
        {/* <div
          className="scrollbar-hide hover:scrollbar-show
         !mb-5 flex w-full gap-3 overflow-auto pb-1"
        >
          <ul className="h-50 flex w-auto flex-row gap-2 py-2">
            {facetCounts
              ?.sort((a, b) => {
                // Ordena para que object_type venha antes de authors
                if (a.field_name === "object_type") return -1;
                if (b.field_name === "object_type") return 1;
                return 0;
              })
              ?.filter(
                (facet) =>
                  facet.field_name === "authors" ||
                  facet.field_name === "object_type",
              )
              ?.flatMap((facet) =>
                facet.counts.map((count) => (
                  <li
                    key={`${facet.field_name}-${count.value}`}
                    className="flex h-[42px] w-fit cursor-pointer items-center justify-center whitespace-nowrap rounded-xl border border-[#CED1D4] px-6 text-xs font-normal text-[#000000] hover:bg-[#F1F1F1]"
                    onClick={() => {
                      const queryLocation = new URLSearchParams(
                        location.search,
                      );
                      var tp = queryLocation.getAll("tp") || undefined;
                      var pa = queryLocation.getAll("pa") || undefined;

                      var authors: string[] = [];
                      var object_type: string[] = [];
                      if (Array.isArray(pa)) {
                        authors = pa;
                      } else {
                        if (pa) {
                          authors.push(pa);
                        }
                      }
                      if (facet.field_name === "authors") {
                        if (Array.isArray(pa)) {
                          authors.push(count.value);
                        } else {
                          authors.push(count.value);
                        }
                      }
                      if (Array.isArray(tp)) {
                        object_type = tp;
                      } else {
                        if (tp) {
                          object_type.push(tp);
                        }
                      }
                      if (facet.field_name === "object_type") {
                        if (Array.isArray(pa)) {
                          object_type.push(count.value);
                        } else {
                          object_type.push(count.value);
                        }
                      }

                      const params = {
                        ...searchParams,
                        pa: authors,
                        tp: object_type,
                      };

                      const queryString = qs.stringify(
                        { ...params },
                        {
                          arrayFormat: "repeat",
                          encodeValuesOnly: true,
                          encode: true,
                        },
                      );

                      // eslint-disable-next-line no-restricted-globals
                      history.push(`${location.pathname}?${queryString}`);
                    }}
                  >
                    {count.value} ({count.count})
                  </li>
                )),
              )}
          </ul>
        </div> */}
        <div className="flex flex-col gap-10">
          <Menu
            qtdConteudos={objetos?.totalItems}
            loading={loading}
            busca={searchParams?.q}
            filter={false}
          />

          <div className="!mt-5 grid w-full grid-cols-1 gap-6 md:mt-0 md:grid-cols-3">
            {loading
              ? Array.from({ length: 9 }).map((_, index) => (
                  <div
                    key={index}
                    className="w-full min-w-0 shrink-0 grow-0 basis-full overflow-hidden rounded-xl bg-slate-100 pl-4 md:basis-1/2 lg:basis-1/3"
                  >
                    <div className="flex h-[187.5px] animate-[shimmer_2s_infinite] bg-gradient-to-r from-transparent  via-gray-400/20 to-transparent md:h-[227.66px] md:w-[400px]" />
                  </div>
                ))
              : objetos?.items?.map((_: ObjetoEducacional, index: number) => (
                  <div key={index} className="p-1">
                    <CardObjetoEducacional
                      objetoEducacional={_}
                      superbusca={true}
                    />
                  </div>
                ))}
          </div>
          <div className="flex w-full items-center justify-center">
            {!loading && objetos && objetos?.totalPages > 1 && (
              <div className="flex w-full justify-center">
                <Pagination
                  count={objetos?.totalPages}
                  onChange={(e, p) => {
                    setPage(p);
                    const queryLocation = new URLSearchParams(location.search);
                    const params = {
                      ...searchParams,
                      page: p,
                    };
                    const queryString = qs.stringify(
                      { ...params },
                      {
                        arrayFormat: "repeat",
                        encodeValuesOnly: true,
                        encode: true,
                      },
                    );

                    // eslint-disable-next-line no-restricted-globals
                    history.push(`${location.pathname}?${queryString}`);
                  }}
                  page={page}
                  variant="outlined"
                  color="primary"
                  className=""
                />
              </div>
            )}
          </div>
        </div>
      </Container>
    </>
  );
}
