import React, { useContext, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import axios from "axios";

import { loginValidator } from "./validator/login.validator";

import {
  EDataLayerEventCategory,
  EDataLayerEventAction,
  useGtm,
} from "../../../hooks/useGtm";
import { upsertUserContext, UserContext } from "../../../context/UserContext";

import { ModalService } from "../../components/Modal";
import { ADEInputPassword, ADEInputText } from "../../components";
import { mostrarModalSolicitaMudancaSenha } from "../../../pages/EsqueciMinhaSenhaPage/components";
import {
  useGetUserByIdCacheLazyQuery,
  useLoginMutation,
  UsersPermissionsUserEntity,
} from "../../../generated/graphql";

import "./styles.module.scss";
import { useHistory, useLocation } from "react-router";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import Container from "../../components/Container";
import { CircleCheckBig, EyeIcon, EyeOff } from "lucide-react";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "js-cookie";
import ReCAPTCHA from "react-google-recaptcha";
import fetchApi from "../../services/rest-dotnet.service";
import client, { authLink } from "../../services/graphql.service";
import { he } from "date-fns/locale";

interface LoginForm {
  username: string;
  password: string;
}

declare global {
  interface Window {
    grecaptcha: any;
  }
}

async function loginApi(usuario: string, senha: string, token: string) {
  const response = await fetchApi(`user/login`, 'POST', JSON.stringify({
    identifier: usuario,
    password: senha,
    token
  }));
  const data = await response.json();

  // Comparar a pontuação com o limiar definido para a ação específica
  return data
}

export const ModalLoginV2: React.FC<{ isMessageGratuito: boolean }> = ({
  isMessageGratuito,
}) => {
  const [error, setError] = useState("");
  const [loginMutation, { loading: loadingLogin }] = useLoginMutation();
  const [loading, setLoading] = useState<boolean>(false);
  const { setUser, stayConnected, setStayConnected } = useContext(UserContext);
  //const [jwt, setJwt] = useState("");
  const history = useHistory();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const recaptchaRef = useRef(null);

  const gtm = useGtm();

  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY || "";

  useEffect(() => {
    // Carregar o script do reCAPTCHA v3 dinamicamente
    const loadRecaptchaScript = () => {
      if (!document.getElementById('recaptcha-script')) {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
        script.id = 'recaptcha-script';
        document.body.appendChild(script);
      }
    };

    loadRecaptchaScript();
  }, []);

  const [GetUserByIdQuery, { loading: loadingUserByID }] =
    useGetUserByIdCacheLazyQuery({
      onError: (error) => {
        setLoading(false);
        console.error(error);
      },
      onCompleted: (data) => {
        try {
          const { findMe: user } = data;

          setUser(user as UsersPermissionsUserEntity);
          const expirationTime = stayConnected
            ? null
            : new Date(new Date().getTime() + 1000 * 60 * 60 * 8);
          upsertUserContext({
            user: user as UsersPermissionsUserEntity,
            expirationTime,
          });
          window.location.reload();
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      },
    });

  const accessLog = async (userId: string) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ROOT}/api/logs`,
        {
          data: {
            userId: userId,
          },
        },
      );
    } catch (error) { }
  };

  const handleSubmit = async (values: LoginForm) => {
    let token = "";
    if ((process.env.REACT_APP_RECAPTCHA_SITE_KEY && !process.env.REACT_APP_RECAPTCHA_SITE_KEY.startsWith("#"))) {
      const recaptchaValue = (recaptchaRef as any).current.getValue();
      if (!recaptchaValue) {
        setError('Por favor, verifique o reCAPTCHA.');
        return;
      }

      token = recaptchaValue;
    }

    try {
      setLoading(true);

      const loginResult = await loginApi(values.username, values.password, token);

      if (!loginResult) {
        setError("E-mail e/ou senha incorretos. Por favor, tente novamente.");
        console.error(
          loginResult.errors.map((item: any) => item.message).join("\n"),
        );
        setLoading(false);
        return;
      }

      if (loginResult?.jwt) {
        const { jwt: _jwt, user: userLogin } = loginResult;
        //setJwt(_jwt!);
        Cookies.set('token', _jwt || '', {
          expires: 7,
          path: '/',
          secure: process.env.REACT_APP_ROOT?.includes('localhost') ? false : true,
          sameSite: process.env.REACT_APP_ROOT?.includes('localhost') ? 'lax' : 'strict',
        });
        await accessLog(userLogin.id);
        setTimeout(() => {
          GetUserByIdQuery();
        }, 500);

      }

      gtm.push(
        EDataLayerEventCategory.ADE_LOGIN,
        EDataLayerEventAction.CLIQUE,
        {
          label: "login-modal",
        },
      );
    } catch (error: any) {
      setError("E-mail e/ou senha incorretos. Por favor, tente novamente.");
      (recaptchaRef as any).current?.reset();
      console.error(error.message || error);
      setLoading(false);
    }
  };

  const openResetPassword = () => {
    // GTM doc linha 50
    gtm.push(EDataLayerEventCategory.ADE_LOGIN, EDataLayerEventAction.CLIQUE, {
      label: "esqueci-senha",
    });

    mostrarModalSolicitaMudancaSenha();
  };

  const formik = useFormik<LoginForm>({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: loginValidator,
    onSubmit: handleSubmit,
  });

  const redirectTo = (url: string) => {
    try {
      const route = location.pathname?.split("/")[1]; // Obtém o primeiro segmento do caminho
      const nextState =
        route === "degustacao-pos" ? { origin: "degustacao-pos" } : undefined;
      history.push(url, nextState); // Navega para a URL com o estado, se aplicável
    } catch (error) {
      history.push(url); // Em caso de erro, simplesmente navega para a URL
    }
  };

  return (
    <Container className="w-[90vw] sm:w-[450px]">
      <div className="flex !w-full flex-col items-center justify-center py-10">
        <div className="flex w-full max-w-[250px] md:max-w-[302px]">
          <form
            onSubmit={formik.handleSubmit}
            noValidate
            className="flex flex-col gap-6 "
          >
            <h4 className="self-center text-center text-xl  font-bold leading-26 text-[#004F92]">
              Entrar{" "}
              {isMessageGratuito
                ? "na sua conta para acessar o conteúdo gratuito"
                : ""}
            </h4>
            <div className="flex flex-col gap-3">
              <div className="flex w-full flex-col">
                <label className="text-sm font-normal leading-normal text-[#313335]">
                  E-mail
                  <Input
                    className="mt-2 h-[50px] w-full rounded-[30px] !bg-[#FAFAFC]"
                    type="email"
                    name="username"
                    placeholder="Digite seu e-mail"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    errorMessage={
                      formik.touched.username ? formik.errors.username : ""
                    }
                  />
                </label>
              </div>

              <div className="flex w-full flex-col">
                <Input
                  icon={
                    showPassword ? (
                      <EyeIcon
                        className="cursor-pointer text-[#004F92]"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    ) : (
                      <EyeOff
                        className="cursor-pointer text-[#004F92]"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    )
                  }
                  label="Senha"
                  placeholder="Digite sua senha"
                  className="w-full rounded-[30px] !bg-[#FAFAFC]"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={
                    formik.touched.password ? formik.errors.password : ""
                  }
                />
              </div>
            </div>

            <div className="flex  justify-end text-sm font-normal leading-normal">
              <span
                className="cursor-pointer text-xs font-normal  text-[#004F92] underline"
                onClick={openResetPassword}
              >
                Esqueci minha senha
              </span>
            </div>
            {(process.env.REACT_APP_RECAPTCHA_SITE_KEY && !process.env.REACT_APP_RECAPTCHA_SITE_KEY.startsWith("#")) && <div className="flex items-center justify-center text-sm font-normal leading-none">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
                ref={recaptchaRef}
                onChange={(token) => {
                  setError("");
                }}
              />
            </div>}
            <div className="flex flex-col items-center justify-between gap-3 md:flex-row">
              <div className="flex items-center justify-center gap-2 text-sm font-normal leading-none">
                <input
                  type="checkbox"
                  className="h-5 w-5 cursor-pointer"
                  checked={stayConnected}
                  onChange={(ev) => setStayConnected(ev.target.checked)}
                />{" "}
                <label className="!text-sm font-normal leading-normal text-[#313335]">
                  Manter conectado
                </label>
              </div>
              <Button
                data-testid="btn-entrar-modal-login"
                type="submit"
                className="w-[249px] md:w-[119px]"
                size="sm"
                disabled={loadingLogin || loadingUserByID || loading}
              >
                {loadingLogin || loadingUserByID || loading
                  ? `Aguarde ...`
                  : `Entrar`}
              </Button>
            </div>

            <div className="flex w-full justify-center text-center">
              {error && (
                <span className="animate-pulse self-center text-sm font-semibold text-red-600">
                  {error}
                </span>
              )}
            </div>
            <hr />
            <div className="flex flex-row flex-nowrap items-center justify-center gap-2">
              <span className="!text-sm font-medium text-[#626466]">
                Ainda não é usuário?
              </span>
              <button
                data-testid="btn-criar-conta"
                type="button"
                className="!text-sm font-semibold text-[#004F92]"
                onClick={() => {
                  redirectTo("/novo-usuario-v2/dados-pessoais");
                  ModalService.hide();

                  // GTM doc linha 34
                  gtm.push(
                    EDataLayerEventCategory.ADE_CADASTRO,
                    EDataLayerEventAction.CLIQUE,
                    {
                      label: "cadastro-modal-login",
                    },
                  );
                }}
              >
                Criar conta
              </button>
            </div>
          </form>
        </div>
      </div>
    </Container>
  );
};

const handleSuccess = () => {
  toast.success(
    <div className="mx-2 flex flex-row items-center gap-3">
      <CircleCheckBig className="h-5 w-5" />
      <div className="flex flex-col">
        <div style={{ fontWeight: 600, fontSize: "12px", color: "#FFFFFF" }}>
          Link enviado para o e-mail informado.
        </div>
      </div>
    </div>,
    {
      icon: false,
      closeButton: false,
      style: {
        backgroundColor: "#21AB27",
        color: "white",
        borderRadius: "35px",
      },
    },
  );
};

export const mostrarModalLogin = (
  isMessageGratuito?: boolean,
  onCloseModal?: () => void,
) => {
  if (ModalService.show) {
    ModalService.show({
      showCloseButton: true,
      onClose: onCloseModal,
      closeOutsideModal: true,
      notFullScreenMobile: true,
      content: <ModalLoginV2 isMessageGratuito={isMessageGratuito || false} />,
    });
  }
};
