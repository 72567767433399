import "./styles.scss";

import { ModalService } from "../../../../shared/components/Modal";

import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../../hooks/useGtm";
import { mostrarModalCertificado } from "../AcessoDeclaracao";
import { useUtils } from "../../../../shared/utils";
import { Button } from "../../../../shared/components/ui/button";
import ParabensConcluiu from "../../../../assets/images/parabens-concluiu.png";
import { PlaylistResponse } from "../../../Playlists/Detalhe";

interface ModalConclusaoProps {
  playlistsDetalhe: PlaylistResponse;
}

export const useModalConclusaoPlaylist = (props: ModalConclusaoProps) => {
  const gtm = useGtm();

  console.log("", props?.playlistsDetalhe);

  const handleFechar = () => {
    ModalService.hide();
  };

  const _renderizar = () => {
    const { formatTitle } = useUtils();
    return (
      <div className="flex flex-col items-center justify-center p-8">
        <img
          src={ParabensConcluiu}
          alt="Imagem de parabéns"
          className="mb-6 mt-9 h-[139.36px] w-[120px] md:h-[185.82px] md:w-[160px]"
        />
        <h3 className="max-w-[300px] text-center text-lg font-bold leading-[22px] text-[#004F92] md:max-w-none md:text-base">
          Parabéns, você concluiu todos os conteúdos da trilha!
        </h3>

        <p className="mt-3 max-w-[250px] text-center text-sm font-normal leading-normal text-[#111316] md:max-w-[350px] ">
          Acesse a sua declaração de participação: ela estará disponível na sua
          área de perfil sempre que precisar.
        </p>

        <div className="mb-10 mt-3 flex w-full flex-col items-center justify-center gap-3 md:flex-row">
          <Button
            data-testid="btn-acessar-declaracao-modal"
            className="h-[39px] w-[215px] rounded-[30px] bg-[#0A589B] px-[28px] py-[12px] text-base font-semibold leading-3 text-[#fff] md:h-[58px]"
            onClick={() => {
              gtm.push(
                EDataLayerEventCategory.ADE_OE_DECLARACAO,
                EDataLayerEventAction.CLIQUE,
                {
                  label: formatTitle(props.playlistsDetalhe.tituloPlaylist),
                  idOe: props?.playlistsDetalhe?.idPlaylist,
                },
              );
              props?.playlistsDetalhe &&
                mostrarModalCertificado(
                  formatTitle(props?.playlistsDetalhe?.tituloPlaylist),
                  props?.playlistsDetalhe?.duracaoPlaylist.toString(),
                  {
                    certificadoPlaylist:
                      props?.playlistsDetalhe?.possuiCertificado,
                    playlistVisualizada:
                      props?.playlistsDetalhe?.playlistVisualizada,
                    tituloPlaylist: props?.playlistsDetalhe?.tituloPlaylist,
                    idPlaylist: props?.playlistsDetalhe?.idPlaylist?.toString(),
                    duracaoPlaylist: props?.playlistsDetalhe?.duracaoPlaylist,
                  },
                );
            }}
          >
            Acessar declaração
          </Button>

          <Button
            data-testid="btn-ver-depois-declaracao"
            type="button"
            variant="outline"
            className="box-border h-[39px] w-[215px] rounded-[30px] border-[1px] border-solid border-[#0A589B] px-[28px] py-[12px] text-base font-semibold leading-3 text-[#0A589B] md:h-[58px] md:w-[144px]"
            onClick={handleFechar}
          >
            Ver depois
          </Button>
        </div>
      </div>
    );
  };

  const mostrar = () => {
    ModalService.show({
      showCloseButton: true,
      notFullScreenMobile: true,
      closeOutsideModal: true,
      content: _renderizar(),
    });
  };

  return { mostrar };
};
