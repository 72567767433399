import Container from "../../../shared/components/Container";
import { Button } from "../../../shared/components/ui/button";
import Thumbnail from "../../../assets/svgs/new/thumbnail-academia.svg";
import { useHistory } from "react-router";
import { useState } from "react";
import DialogPreview from "../../../shared/components/dialog-preview";
import { ComoDesejaEstudarModel } from "../../../models/ComoDesejaEstudar";
import Vimeo from "@u-wave/react-vimeo";
import { cn } from "../../../lib/utils";
import { useUtils } from "../../../shared/utils/index";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../hooks/useGtm";

interface AcademiaDigitalProps {
  qtdConteudos?: number;
}

export default function AcademiaDigital({
  qtdConteudos,
}: AcademiaDigitalProps) {
  const history = useHistory();
  const gtm = useGtm();
  const { formatNumberWithCommas } = useUtils();

  const [selectedItem, setSelectedItem] = useState<ComoDesejaEstudarModel>();

  const redirectTo = (url: string) => {
    history.push(url);
  };

  return (
    <div
      style={{
        background: "linear-gradient(137.1deg, #107B95 6.6%, #1BB28E 98.07%)",
      }}
    >
      <Container className="flex justify-center py-6 md:py-20 tablet:py-10">
        {selectedItem && (
          <DialogPreview
            className="!bg-transparent"
            setOpen={(open) => {
              if (!open) {
                setSelectedItem(undefined);
              }
            }}
          >
            <>
              <Vimeo
                video={selectedItem?.url}
                autoplay={true}
                muted={false}
                className="player-video-preview h-full w-full md:h-[600px] md:w-[1056px]"
              />
            </>
          </DialogPreview>
        )}
        <div className="flex flex-col-reverse items-center gap-9 md:flex-row md:gap-6 tablet:gap-3 ">
          <div className="flex max-w-[363px] flex-col !gap-3 text-center md:!gap-6 md:!text-start tablet:!gap-3 tablet:!text-start">
            <h1 className="text-4xl font-light leading-10 text-white-200 md:text-5.25xl md:leading-48 tablet:text-3xl tablet:leading-30 ">
              Academia{'  '}
              <span className="text-white text-5.25xl !font-bold md:text-5.25xl tablet:text-3xl  tablet:leading-30">
                Digital Einstein
              </span>
            </h1>
            <div className="flex flex-col gap-9 md:gap-6 tablet:gap-6">
              <p className="text-white !text-sm font-normal leading-22 md:!text-lg tablet:!text-sm">
                Explore mais de{" "}
                <span className="font-bold ">
                  {`${formatNumberWithCommas(qtdConteudos || 0)} conteúdos gratuitos`}
                </span>{" "}
                e atualize-se com os especialistas do Hospital Israelita
                <br className="hidden md:block" /> Albert Einstein.
              </p>
              <div>
                <Button
                  data-testid="btn-crie-sua-conta-gratis-academia"
                  variant="primary"
                  size="default"
                  className="bg-white-200 text-[#0A589B] hover:!text-white-200 md:h-[58px]"
                  onClick={() => {
                    redirectTo("/novo-usuario-v2");

                    gtm.push(
                      EDataLayerEventCategory.ADE_CADASTRO,
                      EDataLayerEventAction.CLIQUE,
                      {
                        label: "cadastro-meio",
                      },
                    );
                  }}
                >
                  Crie sua conta grátis
                </Button>
              </div>
            </div>
          </div>
          <div
            className={cn(
              " mt-0 flex w-full min-w-[300px] items-center justify-center overflow-hidden rounded-[11.8px] md:mt-8 md:min-w-[558px] tablet:min-w-[378px] ",
            )}
          >
            <img
              onClick={() =>
                setSelectedItem({
                  url: "https://vimeo.com/878303438/7f6c0300b0",
                  id: 0,
                  thumbnail: Thumbnail,
                  tipo: "Academia Digital",
                })
              }
              data-testid="img-video-academia"
              src={Thumbnail}
              alt="Academia Digital"
              className={cn(
                "h-full w-full cursor-pointer object-cover object-center",
                selectedItem?.id ? "hidden" : "w-full",
              )}
            />
          </div>
        </div>
      </Container>
    </div>
  );
}
